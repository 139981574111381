import mainImage from "./assets/DSC07672.jpg";
import aboutImage from "./assets/DSC07683.jpg";
function App() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        direction: "rtl", // Setting direction to RTL for the entire page
      }}>
      <section
        style={{
          padding: "125px 26px",
          backgroundColor: "#fba833",
          color: "white",
        }}>
        <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
          <div
            style={{
              display: "flex",
              gap: "24px",
              justifyContent: "space-between",
            }}
            className="flex-col md:flex-row">
            <div
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "16px",
              }}>
              <div
                style={{
                  gap: "8px",
                  display: "flex",
                  flexDirection: "column",
                }}>
                <h1
                  style={{
                    fontSize: "2rem",
                    fontWeight: "bold",
                    lineHeight: "1.2",
                  }}>
                  ارتقِ بفعالياتك مع ساوند برو
                </h1>
                <p
                  style={{
                    maxWidth: "600px",
                    color: "white",
                    opacity: "0.8",
                    fontSize: "1.2rem",
                  }}>
                  احصل على مزيج مثالي من الصوت، الإضاءة، والإنتاج لفعاليتك
                  القادمة.
                </p>
              </div>
            </div>
            <img
              src={mainImage}
              width="550"
              height="550"
              alt="Hero"
              style={{
                borderRadius: "12px",
                objectFit: "cover",
                maxWidth: "100%",
              }}
            />
          </div>
        </div>
      </section>
      <section
        style={{
          padding: "120px 26px",
        }}
        id="about">
        <div style={{ margin: "0 auto" }}>
          <div
            style={{ display: "flex", gap: "24px" }}
            className="flex-col md:flex-row">
            <div
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "16px",
              }}>
              <div
                style={{
                  gap: "8px",
                  display: "flex",
                  flexDirection: "column",
                }}>
                <h2
                  style={{
                    fontSize: "2rem",
                    fontWeight: "bold",
                    lineHeight: "1.2",
                  }}>
                  عن ساوند برو
                </h2>
                <p
                  style={{
                    color: "#333",
                    fontSize: "1.2rem",
                  }}>
                  ساوند برو هي شركة رائدة في إدارة الفعاليات، متخصصة في تقديم
                  خدمات صوتية وإضاءة وإنتاج على أعلى مستوى. مع سنوات من الخبرة،
                  أتقنّا فن خلق فعاليات لا تُنسى تُبهر الجمهور.
                </p>
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "16px",
                }}>
                <div
                  style={{
                    borderRadius: "8px",
                    backgroundColor: "#fba833",
                    padding: "32px",
                    color: "white",
                  }}>
                  <h3 style={{ fontSize: "1.25rem", fontWeight: "bold" }}>
                    الصوت
                  </h3>
                  <p style={{ fontSize: "0.875rem" }}>
                    تجارب صوتية غامرة ترتقي بفعاليتك.
                  </p>
                </div>
                <div
                  style={{
                    borderRadius: "8px",
                    backgroundColor: "#fba833",
                    padding: "32px",
                    color: "white",
                  }}>
                  <h3 style={{ fontSize: "1.25rem", fontWeight: "bold" }}>
                    الإضاءة
                  </h3>
                  <p style={{ fontSize: "0.875rem" }}>
                    تصميمات إضاءة خلابة تضفي الجو المناسب.
                  </p>
                </div>
                <div
                  style={{
                    borderRadius: "8px",
                    backgroundColor: "#fba833",
                    padding: "32px",
                    color: "white",
                  }}>
                  <h3 style={{ fontSize: "1.25rem", fontWeight: "bold" }}>
                    الإنتاج
                  </h3>
                  <p style={{ fontSize: "0.875rem" }}>
                    إنتاج فعاليات متكامل يجسد رؤيتك.
                  </p>
                </div>
                <div
                  style={{
                    borderRadius: "8px",
                    backgroundColor: "#fba833",
                    padding: "32px",
                    color: "white",
                  }}>
                  <h3 style={{ fontSize: "1.25rem", fontWeight: "bold" }}>
                    تخطيط الفعاليات
                  </h3>
                  <p style={{ fontSize: "0.875rem" }}>
                    خدمات شاملة لتخطيط الفعاليات لضمان تجربة خالية من التوتر.
                  </p>
                </div>
              </div>
            </div>
            <img
              src={aboutImage}
              width="550"
              height="550"
              alt="About"
              style={{
                borderRadius: "12px",
                objectFit: "cover",
                maxWidth: "100%",
              }}
            />
          </div>
        </div>
      </section>
      {/* <section className="p-8">
        <h2 className="text-3xl font-bold mb-6">عن ساوند برو</h2>
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="md:w-1/2 space-y-4">
            <p>
              ساوند برو هي شركة رائدة في إدارة الفعاليات، متخصصة في تقديم خدمات
              صوتية وإضاءة وإنتاج على أعلى مستوى. مع سنوات من الخبرة، أتقنّا فن
              خلق فعاليات لا تُنسى تبهر الجمهور.
            </p>
          </div>
          <div className="md:w-1/2 mt-4 md:mt-0">
            <img
              src={aboutImage}
              width="550"
              height="550"
              alt="About"
              style={{
                borderRadius: "12px",
                objectFit: "cover",
                maxWidth: "100%",
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-8">
          {["الإضاءة", "الصوت", "تخطيط الفعاليات", "الإنتاج"].map(
            (service, index) => (
              <div
                key={index}
                className="bg-[#FFA500] p-4 rounded-lg text-white">
                <h3 className="font-bold mb-2">{service}</h3>
                <p className="text-sm">
                  خدمات {service} عالية الجودة لفعالياتك.
                </p>
              </div>
            )
          )}
        </div>
      </section> */}
      <section className="bg-gray-100 sm:p-32 p-0">
        <h2 className="text-3xl font-bold mb-6 flex justify-center items-center">
          خدماتنا
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-white p-10 rounded-lg shadow-md">
            <h3 className="text-xl font-bold mb-2">فعاليات الشركات</h3>
            <p>نقدم حلول صوتية وإضاءة متكاملة لاجتماعات وحفلات الشركات</p>
          </div>
          <div className="bg-white p-10 rounded-lg shadow-md">
            <h3 className="text-xl font-bold mb-2">الحفلات الموسيقية</h3>
            <p>نضمن تجربة صوتية استثنائية لجميع أنواع الحفلات الموسيقية</p>
          </div>
          <div className="bg-white p-10 rounded-lg shadow-md">
            <h3 className="text-xl font-bold mb-2">المؤتمرات</h3>
            <p>نوفر أنظمة صوت وعرض عالية الجودة للمؤتمرات والندوات</p>
          </div>
        </div>
      </section>
      <footer
        style={{
          padding: "16px",
          backgroundColor: "#333",
          color: "white",
          textAlign: "center",
        }}>
        <p style={{ margin: "0", fontSize: "0.875rem" }}>
          © 2024 ساوند برو. جميع الحقوق محفوظة.
        </p>
      </footer>
    </div>
  );
}

export default App;
